import "bootstrap/dist/css/bootstrap.min.css";
import "./css/bootstrap.min.css";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import { getData, postData } from "./helpers/Data";

import { AuthContext } from "./helpers/Contexts";
import CacheBuster from "./CacheBuster";
import Navigator from "./navigation/Navigator";
import OfflineModal from "./screens/OfflineModal";
import React from "react";
import { ToastContainer } from "react-toastify";

interface IProps {
  location?: any;
}

interface IState {
  User?: {} | undefined;
  companies: [];
  isAuthed: boolean;
  selectedCompany: {};
  setAuthed: any;
  setCompany: any;
  setUser: any;
  userChecked: boolean;
}

export default class App extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.setAuthed = this.setAuthed.bind(this);
    this.setUser = this.setUser.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.state = {
      companies: [],
      isAuthed: false,
      selectedCompany: {},
      setAuthed: this.setAuthed,
      setCompany: this.setCompany,
      setUser: this.setUser,
      userChecked: false,
    };
  }

  componentDidMount() {
    this.setAuthed();
  }

  setAuthed() {
    let bearerToken = localStorage.getItem("bearer-token");
    if (bearerToken != null) {
      let user = getData("users/userinfo").then((user) => {
        getData("companies").then((companies) => {
          let PNT = localStorage.getItem("notification-token");
          if (user.pushNotificationToken != PNT) {
            user.pushNotificationToken = PNT;
            postData("users/pushnotificationtoken", { Id: user.id, PushNotificationToken: PNT });
          }
          this.setUser(user, companies);
        });
      });
    } else {
      this.setState({ userChecked: true });
    }
  }

  setUser(user: any, companies: any) {
    if (user) {
      this.setState({
        User: user,
        userChecked: true,
        isAuthed: true,
        companies: companies,
        selectedCompany: companies.find((comp: any) => comp.id == localStorage.getItem("selected-company-id")) ?? companies[0],
      });
    }
  }

  setCompany(company: any, ignoreReload?: boolean) {
    if (company) {
      this.setState({ selectedCompany: company }, () => localStorage.setItem("selected-company-id", company.id));
      if (!ignoreReload) {
        window.location.reload();
      }
    }
  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }: { loading: boolean; isLatestVersion: boolean; refreshCacheAndReload: Function }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <div className="App">
              <AuthContext.Provider value={this.state}>
                <Navigator location={this.props.location} />

                <OfflineModal />
              </AuthContext.Provider>
              <ToastContainer />
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}
